import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private url = environment.url;

  public static logado:EventEmitter<boolean> = new EventEmitter<boolean>();
  //Variavel utilizada na home para verificar se o usuário tem permissão de acesso ao módulos
  public static naoAutorizado:EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
  ) { }


  public fazerLogin(username: string, password: string): Observable<any> {
    let user = {
      username,
      password
    }
    return this.http.post<any>(`${this.url}/login`, user);
  }


  public salvarSessionStorageTokenSgq(token:string){
   return sessionStorage.setItem("token_sgq",token);
  }


  public consultarSessionStorageTokenSgq(){
    return sessionStorage.getItem("token_sgq");
  }

  public recuperarSenha(email:string): Observable<any>{
    return this.http.get<any>(`${this.url}/recuperar-senha/${email}`);
  }

  public verificaPermissaoDeTrocaDeSenha(email: string, token: string){
    return this.http.get<any>(`${this.url}/recuperar-senha/${email}/${token}`);
  }

  public cadastrarNovaSenha(email: string, token: string, senha: string){
    return this.http.get<any>(`${this.url}/recuperar-senha/${email}/${token}/${senha}`);
  }

  estaLogado(): boolean {
    const token = this.consultarSessionStorageTokenSgq();
    return token != null && !this.jwtHelper.isTokenExpired(token);
  }

  decodificarToken() {
    const token = this.consultarSessionStorageTokenSgq();
    return this.jwtHelper.decodeToken(token!)
  }

  temPermissao(permissoesNecessarias: string[]): boolean {
    let dados = this.decodificarToken();
    const permissoesUsuario = dados.permissoes; // Array de permissões do token  
    // Verifica se ao menos uma permissão do array necessário está presente nas permissões do usuário
    return permissoesNecessarias.some(permissao => permissoesUsuario.includes(permissao));
  }

}
